#about_me_survey {
    margin-bottom: 80px;
}

@media only screen and (min-width:601px){
    .question-group {
        padding-left: 25px;
        padding-right: 50px;
    }
}

#assessment_survey,
#about_me_survey,
#wpb5_survey {
    min-height: 100vh;
    background-color: #f1f1f1;
    padding-top: 30px;
    padding-bottom: 40px;

    .text-input {
		margin-left: -5px !important;
	}

    .sub-heading {
        text-align: center;
        font-size: medium;
    }

    .section-subheading {
        margin: 10px;
        padding: 5px 10px 5px;
        border-radius: 10px;
    }

    .question-group {
        padding-left: 30px;
    }

    .question {
        // padding: 0px 0px 10px 0px;

        .question-text {
            font-size: 16px;
        }

        .question-number {
            margin-bottom: -10px;
            font-weight: 700;
        }

        .question-scale {
            // display: contents;
            margin: 0px auto 15px;

            .answer-button {
                margin-top: -10px;
                width: 20%;
                display: inline-block;

                &.wide {
                    width: 100%;
                }

                button {
                    &:hover {
                        background-color: 'none';
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {

    #assessment_survey,
    #wpb5_survey {
        .question-group {
            padding-left: 0px;
        }
    }
}