footer {
    background-color: #ffffff;
    // -webkit-box-shadow: 0px 1px 5px 1px #aaa;
    // box-shadow: 0px 1px 5px 1px #aaa;
    font-size: 13px;

    padding: 0px 32px;
    gap: 6px;

    &.fixed-bottom {
        bottom: 0;
    }

    &.fixed-bottom,
    &.fixed-top {
        position: fixed;
        right: 0;
        left: 0;
        z-index: 1100;
    }

    .footer-link {
        margin: 0px 0px 0px 10px;
    }
}