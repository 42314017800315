.card-title {
    background-color: #003da7;
    color: #ffffff;
}

.instructions {
    margin: 5px 25px;
    font-size: 14pt;
    padding-bottom: 0px;
}

.review-info {
    border-radius: 12px;
    border: 1px solid #003DA7 !important;
    padding: 5px !important;
    font-size: 14px;
    background-color: white;
    padding: 10px;
}

.question-card {
    // border: 1px solid #000000;
    border-radius: 12px;
    -webkit-box-shadow: 0px 1px 5px 1px #aaa;
    box-shadow: 0px 1px 5px 1px #aaa;
    font-size: 14px;
    background-color: white;
    padding: 10px;
}

.extra-padding {
    margin-top: 60px;
}

.question-title {
    margin-top: -8px !important;
    border-radius: 10px 10px 0 0;
}

.question-subtitle {
    margin-top: -15px !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    border-radius: 10px 10px 0 0;
}

.question-subtitle-html {
    margin-top: -30px !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    border-radius: 10px 10px 0 0;
    margin-bottom: -15px !important;
    line-height: 0.75cm !important;
}

.copyright {
    text-align: center;
    font-size: small;
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    border: 1px solid #D6D6D7;
}

.tandc {
    border-radius: 10px;
    border: 1px solid #D6D6D7;
    padding: 5px;
}

.warnings {
    color: red;
    align-self: center;
    text-align: center;
    font-size: small;
}

.section-card {
    margin: 10px;
    padding: 1px;
}

.text-title {
    font-weight: 900;
    color: #3B3B3B;
}

.rounded-card{
    border-radius: 20px;
    background-color: white;
    padding: 25px;
}

.check-box {
    margin-left: -1.9rem !important;
    margin-top: 0px !important;
    margin-bottom: -10px !important;
}

.solid-box {
    border-radius: 10px !important;
    border: 1px solid #D6D6D7 !important;
}