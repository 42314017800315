.button-style {
    border-radius: 100px;
    box-shadow: 0px 3px 1px -2px;
}

.instrument-status {
    border-radius: 10px;
}

.survey-card {
    padding: 5px 10px 15px 10px;
}

.survey-links {
    padding: 5px;
}

.survey-warnings {
    padding: 8px;
    border-radius: 10px;
}

.section-warnings {
    padding: 5px 5px 10px 10px;
    border-radius: 10px;
    text-align: center;
}

.button-center {
    align-content: center;
    height: 35px;
}

.icon-style {
    width: 20px;
    height: 20px;
    align-self: center;
    margin: 0px 10px 3px 10px;
    transform: rotate(320deg);
}

.link-style {
    align-self: center; 
    color: #FFFFFF;
    margin: 2px;
}

.icon-tick {
    width: 20px;
    height: 20px;
    align-self: center;
    margin: 0px 5px 5px 5px;
}

.sub-header {
    text-align: center;
}

.sub-header-secondary {
    text-align: left;
    // padding-left: 20px;
    align-self: flex-start;
    width: 100%;
}

.sub-header-secondary-rtl {
    text-align: right;
    // padding-right: 20px;
    align-self: flex-end;
    width: 100%;
}

.button-header {
    align-self: center;
    height: 20px;
    font-weight: 400;
    background-color: #EBF8F6 !important;
    border-radius: 10px !important;
}

.selected-button {
    border-radius: 10px !important;
    background-color: #CDE9E6 !important;
    font-weight: 700;
    padding: 8px 16px;
}

.border-bottom-style {
    border-bottom: 1 !important;
    border-bottom-color: "#D6D6D7" !important;
    border-radius: 0 !important;
}

table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 0.75rem !important;
}

thead th {
    text-align: left;
    margin-left: 2em;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 0.009rem;
    border-bottom: 1px solid #ccc;
}

thead button {
    border: 0;
    font-family: inherit;
    font-weight: 700;
    font-size: inherit;
    padding: 0.5em;
    margin-bottom: 1px;
}

tbody td {
    padding: 0.5rem 1.5rem;
    border-bottom: 1px solid #ccc;
}