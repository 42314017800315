@font-face {
    font-family: "NotoSans-Regular";
    src: url(./assets/fonts/latin/NotoSans-Regular.ttf) format('truetype');
}

@font-face {
    font-family: "NotoSans-Italic";
    src: url(./assets/fonts/latin/NotoSans-Italic.ttf) format('truetype');
}

@font-face {
    font-family: "NotoSans-BoldItalic";
    src: url(./assets/fonts/latin/NotoSans-BoldItalic.ttf) format('truetype');
}

@font-face {
    font-family: "NotoSans-Bold";
    src: url(./assets/fonts/latin/NotoSans-Bold.ttf) format('truetype');
}

@font-face {
    font-family: "NotoSans-Black";
    src: url(./assets/fonts/latin/NotoSans-Black.ttf) format('truetype');
}

@font-face {
    font-family: "NotoSansArabic-Regular";
    src: url(./assets/fonts/arabic/NotoSansArabic-Regular.ttf) format('truetype');
}

@font-face {
    font-family: "NotoSansArabic-Bold";
    src: url(./assets/fonts/arabic/NotoSansArabic-Bold.ttf) format('truetype');
}

@font-face {
    font-family: "NotoSansArabic-SemiBold";
    src: url(./assets/fonts/arabic/NotoSansArabic-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: "NotoSansSC-Regular";
    src: url(./assets/fonts/chinese/NotoSansSC-Regular.otf) format('truetype');
}

@font-face {
    font-family: "NotoSansSC-Bold";
    src: url(./assets/fonts/chinese/NotoSansSC-Bold.otf) format('truetype');
}

.de-DE,
.en-GB,
.en-US,
.es-ES,
.es-MX,
.fr-FR,
.it-IT,
.nl-NL,
.ru-RU,
.pl-PL,
.pt-BR,
.pt-PT {

    a,
    body,
    label,
    li,
    p,
    div {
        font-family: "NotoSans-Regular", sans-serif;
    }

    div span {
        font-family: "NotoSans-Regular", sans-serif;
    }

    h1,
    h2,
    h3 {
        font-family: "NotoSans-Bold", sans-serif !important;
    }

    h4 {
        font-family: "NotoSans-Bold", sans-serif !important;
    }

    h5 {
        font-family: "NotoSans-Bold", sans-serif !important;
    }

    h6 {
        font-family: "NotoSans-Regular", sans-serif !important;
    }

    button {
        font-family: "NotoSans-SemiBold", sans-serif !important;
    }
}

.ar-SA {

    a,
    body,
    label,
    li,
    p,
    div {
        font-family: 'VideoJS', "NotoSansArabic-Regular", sans-serif !important;
    }

    div span {
        font-family: 'VideoJS', "NotoSansArabic-Regular", sans-serif !important;
    }

    h1,
    h2,
    h3 {
        font-family: "NotoSansArabic-Bold", sans-serif !important;
    }

    h4,
    h5,
    h6 {
        font-family: "NotoSansArabic-SemiBold", sans-serif !important;
    }

    button {
        font-family: "NotoSansArabic-SemiBold", sans-serif !important;
    }
}

.zh-CN {

    a,
    body,
    label,
    li,
    p,
    div {
        font-family: 'VideoJS', "NotoSansSC-Regular", sans-serif !important;
    }

    div span {
        font-family: 'VideoJS', "NotoSansSC-Regular", sans-serif !important;
    }

    h1,
    h2,
    h3 {
        font-family: "NotoSansSC-Bold", sans-serif !important;
    }

    h4,
    h5,
    h6 {
        font-family: "NotoSansSC-Bold", sans-serif !important;
    }

    button {
        font-family: "NotoSansSC-Bold", sans-serif !important;
    }
}