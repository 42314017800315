.back-button {
    align-self: flex-start;
    color: #003DA7;
    width: 20px;
    height: 20px;
    margin: 5px;
}

#profile {
    background-color: #f1f1f2;
}

#image-style {
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
    background-color: #EBF3F6;
    margin-bottom: 25px;
}

#info {
    height: fit-content;
    border-radius: 12px;
}

#labels {
    margin: 0px 10px;
}

.header {
    text-align: left;
    margin-left: -30px;
    margin-right: -20px; 
    width: 110%;
}

.save-button {
    align-self: center;
    text-align: center;
    padding: 5px;
    margin-bottom: 10px;
}
