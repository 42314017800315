.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.left {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
}

.right {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    text-align: right;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}