header {
	// -webkit-box-shadow: 0px 1px 5px 1px #aaa !important;
	// box-shadow: 0px 1px 5px 1px #aaa !important;
	background-color: #1f222c !important;
	position: fixed !important;
	top: 0;
	z-index: 1200;
}

.tab-icon {
	background-color: #EBF8F6;
	border-radius: 10px !important;
	margin: 5px 5px !important;
	width: auto;
	height: auto;
}

.tab-border {
	border-radius: 5px !important;
}

.tab-group {
	background-color: #CDE9E6;
	margin: 0px 5px 5px 10px !important;
	height: 30px;
}

.nav-logo {
	height: 45px;
}

.menu-button {
	&:hover {
		background-color: #a267a7 !important;
	}
}

.sub-heading {
	background-color: #ffffff;
	padding: 8px, 32px, 8px, 32px;
	height: 54px;
	border-top: 1px solid #C3C6CF !important;
}