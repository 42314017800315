#survey_main {
    min-height: 100vh;
    background-color: #f1f1f2;
    margin: 150px 0 0 0;
    padding: 10px 32px 80px 32px;
    color: lighten(#050505, 25%);
}

#survey_nowork {
    margin: 10px 0 0 0;
}

.rtl {
    direction: rtl;
}