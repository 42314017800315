$ccl-primary-blue: #003da7;

.loading {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    width: 100vw;
    height: 100vh;
    background: #fff;
    text-align: center;
    padding-top: 18%;
    opacity: 0.5;

    .ball {
        color: $ccl-primary-blue;
        background-color: rgba(0, 0, 0, 0);
        border-width: 10px;
        border-style: solid;
        opacity: 0.9;
        border-top-color: transparent !important;
        border-left-color: transparent !important;
        -webkit-border-radius: 60px;
        -moz-border-radius: 60px;
        border-radius: 60px;
        -webkit-box-shadow: 0 0 35px #fff;
        -moz-box-shadow: 0 0 35px #fff;
        box-shadow: 0 0 35px #fff;
        width: 100px;
        height: 100px;
        margin: 0 auto;
        -moz-animation: spin 0.8s infinite linear;
        -o-animation: spin 0.8s infinite linear;
        animation: spin 0.8s infinite linear;
        -webkit-animation: spin 0.8s infinite linear;
    }

    .ball1 {
        color: $ccl-primary-blue;
        background-color: rgba(0, 0, 0, 0);
        border-width: 10px;
        border-style: solid;
        opacity: 0.9;
        border-top-color: transparent !important;
        border-left-color: transparent !important;
        -webkit-border-radius: 35px;
        -moz-border-radius: 35px;
        border-radius: 35px;
        -webkit-box-shadow: 0 0 15px #fff;
        -moz-box-shadow: 0 0 15px #fff;
        box-shadow: 0 0 15px #fff;
        width: 50px;
        height: 50px;
        margin: 0 auto;
        position: relative;
        top: -75px;
        -moz-animation: spinoff 0.8s infinite linear;
        -o-animation: spinoff 0.8s infinite linear;
        animation: spinoff 0.8s infinite linear;
        -webkit-animation: spinoff 0.8s infinite linear;
    }
}

@-moz-keyframes spin {
    0% {
        -moz-transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(360deg);
    }
}

@-moz-keyframes spinoff {
    0% {
        -moz-transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(-360deg);
    }
}

@-o-keyframes spin {
    0% {
        -o-transform: rotate(0deg);
    }

    100% {
        -o-transform: rotate(360deg);
    }
}

@-o-keyframes spinoff {
    0% {
        -o-transform: rotate(0deg);
    }

    100% {
        -o-transform: rotate(-360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes spinoff {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(-360deg);
    }
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes spinoff {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(-360deg);
    }
}