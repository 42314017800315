@media only screen and (min-width:601px){
.radioClass {
	margin-left: 2rem;
}

.dropdownClass {
	width: 98%;
	left: 1.2rem;
	background-color: #ffffff;
}

.optionClass:hover {
	color: #00C4B4;
}

.checkboxClass {
	padding-left: 1.1rem;
}

.checkboxSubText {
	padding-left: 3rem;
	letter-spacing: 0.00938em !important;
}

.MuiMenu-paper {
	height: 12em;
	bottom: 15.625em;
}

.MuiSelect-select:focus {
	background-color: white;
	border: none;
}

.btn-style {
	border-radius: 100px !important;
}
}

@media only screen and (max-width: 600px){
	.radioClass {
		margin-left: 2rem;
	}
	
	.dropdownClass {
		width: 96%;
	    left: 1rem;
		right: 1rem;
		background-color: #ffffff;
	}
	
	.optionClass:hover {
		color: #00C4B4;
	}

	.MuiMenu-paper {
		height: 15em;
		bottom: 15.625em;
	}
	
	.MuiSelect-select:focus {
		background-color: white;
		border: none;
	}
	
	
	.checkboxClass {
		padding-left: 1.1rem;
	}

	.checkboxSubText {
		padding-left: 3rem;
		letter-spacing: 0.00938em !important;
	}
	
  .dialogClass{
	  width: 30%;
  }
	
	.btn-style {
		border-radius: 100px !important;
	}
}